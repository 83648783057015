@use '../styles/settings' as settings;

.text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  &--default {
    @include settings.fontSizeSettings(default);
    font-weight: settings.$light-weight;
  }

  &--h1 {
    @include settings.fontSizeSettings(headline);
    font-weight: settings.$bold-weight;
    margin-bottom: settings.$spacing;
  }

  &--h2 {
    @include settings.fontSizeSettings(sub-headline);
    font-weight: settings.$base-weight;
  }

  &--h3,
  &--h4,
  &--h5,
  &--h6 {
    @include settings.fontSizeSettings(instruction);
    font-weight: settings.$base-weight;
  }
}
