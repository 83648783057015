@import '../styles/settings';
@import '../styles/base';
@import '../styles/colors';

.toast {
  @include fontSizeSettings(default);

  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  min-height: 304px;
  padding: calc($spacing / 2);
  pointer-events: none;
  position: fixed;
  right: 0;
  text-align: center;
  transform-origin: bottom;
  transform: translateY(50vh);
  width: 100vw;
  z-index: 10000;

  &--theme-default {
    background: white;
    color: #000;
    box-shadow: 0 -10px 60px colorVar('border');
  }

  &--theme-error {
    background: colorVar('error');
    color: colorVar('error-font');
  }

  &--opening,
  &--closing {
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
  }

  &--opening {
    animation-name: slide-up-bounce;
    animation-timing-function: ease;
  }

  &--open {
    transform: translateY(20px);
  }

  &--open,
  &--opening {
    border-top: 2px solid colorVar('border');
    pointer-events: auto;
  }

  &--closing {
    animation-name: slide-down-bounce;
    animation-timing-function: ease;
  }
}

@keyframes slide-up-bounce {
  0%   { transform: translateY(500px); }
  70%  { transform: translateY(0); }
  100% { transform: translateY(20px); }
}

@keyframes slide-down-bounce {
  0%   { transform: translateY(20px); }
  30%  { transform: translateY(0); }
  100% { transform: translateY(500px); }
}
