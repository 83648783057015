// spacings and edges
$spacing: 80px;
$small-spacing: 32px;
$edge: 16px;

// fonts
$default-font: 'Roboto', sans-serif;

// font-weight
$light-weight: 400;
$base-weight: 500;
$bold-weight: 900;

$font-sizes: (
  headline: 10.4rem,
  sub-headline: 6.4rem,
  instruction: 4.8rem,
  default: 4rem,
  meta: 3.2rem,
  caption: 2.5rem,
);

@function font-size($size) {
  @return map-get($font-sizes, $size);
}

$line-heights: (
  headline: 12.8rem,
  sub-headline: 8rem,
  instruction: 6.4rem,
  default: 5.6rem,
  meta: 5.6rem,
  caption: 3.2rem,
);

@function line-height($height) {
  @return map-get($line-heights, $height);
}

@mixin fontSizeSettings($name) {
  font-size: font-size($name);
  line-height: line-height($name);
}


