.notification {
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  position: fixed;
  background: colorVar('error');
  color: colorVar('error-font');
  padding: calc($spacing / 2);
  width: 100vw;
  min-height: 304px;

  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;

  transform: translateY(50vh);
  transform-origin: bottom;
  animation-iteration-count: 1;
  animation-duration: 0.5s;

  &--is-visible {
  transform: translateY(20px);
    animation-name: bottom-bounce;
    animation-timing-function: ease;
  }

  &--is-hidden {
    animation-name: bottom-bounce-reverse;
    animation-timing-function: ease;
  }
}

@keyframes bottom-bounce {
  0%   { transform: translateY(500px); }
  70%  { transform: translateY(0); }
  100% { transform: translateY(20px); }
}

@keyframes bottom-bounce-reverse {
  0%   { transform: translateY(20px); }
  30%  { transform: translateY(0); }
  100% { transform: translateY(500px); }
}
