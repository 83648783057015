* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 10px; // now 1rem is 10px instead of 16px
  touch-action: pan-y; // disable pinch
}

body {
  margin: 0;
  padding: 0;
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  font-family: $default-font;
  font-weight: $light-weight;

  .theme--tegut-eixae6,
  .theme--tegut-demo-n94qdzli,
  .theme--snabble-supermarket-71u283,
  .theme--demo {
    letter-spacing: -1px;
  }

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

h1, h2, h3, h4, h5, h6, strong {
  font-weight: $bold-weight;
}

::selection { background: transparent; }

*:focus {
  outline: none;
}

h1 {
  @include fontSizeSettings(headline);
  color: colorVar(background-font-headline);
}

p {
  @include fontSizeSettings(default);
}

button, input, textarea {
  font-family: var(--theme-font-family);
  font-weight: $base-weight;
}

svg {
  pointer-events: none;
}

.theme {
  --theme-font-family: $default-font;
  font-family: var(--theme-font-family);

  .mdc-typography {
    font-family: var(--theme-font-family);
  }

  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: colorVar(background);
  color: colorVar(background-font);

  &--darkmode {
    background-color: colorVar(background, true);
    color: colorVar(background-font, true);
  }
}

a {
  cursor: pointer;
  font-weight: $bold-weight;
  color: colorVar(submit-button);
}

hr {
  width: 200px;
  border: none;
  border-bottom: 5px solid;
  border-bottom-color: colorVar(border);
}

.animation {
  will-change: transform;

  &--spin {
    will-change: transform;
    animation: spin 6s linear infinite;
  }
}

.theme:not(.theme--less-animation) .animation {
  &--bounce {
    animation: bounce 1s infinite;
  }

  &--scale {
    &-enter {
      opacity: 0.01;
      transform: scale(1.1);

      &-active {
        opacity: 1;
        transform: scale(1);
        transition: all 300ms;
      }
    }

    &-exit {
      opacity: 1;
      transform: scale(1);
      &-active {
        opacity: 0.01;
        transform: scale(1.1);
        transition: all 300ms;
      }
    }
  }
}

.theme--less-animation .animation {
  &--scale {
    &-enter {
      opacity: 0.01;

      &-active {
        opacity: 1;
        transition: opacity 300ms;
      }
    }

    &-exit {
      opacity: 1;
      &-active {
        opacity: 0.01;
        transition: opacity 300ms;
      }
    }
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0);
  }
}
