.default {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background-color: colorVar(background);
  background-image: var(--theme-background-image-sub);
  background-repeat: no-repeat;
  background-position: top center;

  @extend %fullview;

  &__content {
    @extend %content;
  }

  &__text {
    @include fontSizeSettings(instruction);
    margin-bottom: $spacing;
  }

  &__hint {
    font-size: 3rem;
    line-height: 3.6rem;
    position: absolute;
    bottom: $spacing;
    width: 100%;
    text-align: center;
    left: 0;
    font-weight: $base-weight;
    padding-left: $spacing * 2;
    padding-right: $spacing * 2;
  }

  &__btn {
    margin: 100px 20px;
    min-width: 30vw;
  }

  &__image {
    margin-bottom: 100px;

    .brandcolor {
      fill: colorVar(svg-main);
    }
  }

  #terminal-spinner-verifone {
    animation: terminal-spinner-verifone 2200ms steps(12) infinite;
    transform-origin: 18px 18px;
  }

  @keyframes terminal-spinner-verifone {
    from { transform: translate(155px, 109px) rotate(0deg) }
    to   { transform: translate(155px, 109px) rotate(360deg) }
  }

  #terminal-spinner-q30 {
    animation: terminal-spinner-q30 2200ms steps(12) infinite;
    transform-origin: 18px 18px;
  }

  @keyframes terminal-spinner-q30 {
    from {
      transform: translate(174px, 168px) rotate(0deg)
    }

    to {
      transform: translate(174px, 168px) rotate(360deg)
    }
  }

  #terminal-spinner-feig {
    animation: terminal-spinner-feig 2200ms steps(12) infinite;
    transform-origin: 18px 18px;
  }

  @keyframes terminal-spinner-feig {
    from { transform: translate(191px, 94px) rotate(0deg) }
    to   { transform: translate(191px, 94px) rotate(360deg) }
  }

  #rightfoot {
    animation: rightfoot 350ms linear infinite alternate;
    transform-origin: 0px 22px;
  }

  @keyframes rightfoot {
    0% { transform: translate(36px, 337px) rotate(0deg) }
    100% { transform: translate(36px, 337px) rotate(-10deg) }
  }

  #receipt {
    animation: receipt 350ms linear infinite alternate;
  }

  @keyframes receipt {
    0% { transform: translate(124px, 66px) rotate(4deg) }
    100% { transform: translate(124px, 66px) rotate(-4deg) }
  }

  #laser {
    animation: laser 2500ms linear infinite alternate;
  }

  @keyframes laser {
    0% { transform: translate(-20px) translateY(20px); }
    100% { transform: translate(110px) translateY(32px); }
  }

  #laserblink {
    animation: laserblink 190ms step-start 0s infinite;
  }

  @keyframes laserblink {
    50% { opacity: 0.2; }
  }

  #cig1 {
    animation: cig1 2s ease infinite;
    transform-origin: 55px 360px;
  }

  @keyframes cig1 {
    0% { transform: scale(1,1) translateY(30px) translateX(21px); }
    5% { transform: scale(1.1,.9) translateY(30px) translateX(21px); }
    15% { transform: scale(.9,1.1) translateY(30px) translateX(21px); }
    25% { transform: scale(1.05,.95) translateY(30px) translateX(21px); }
    28% { transform: scale(1,1) translateY(26px) translateX(21px); }
    50% { transform: scale(1,1) translateY(30px) translateX(21px); }
    100% { transform: scale(1,1) translateY(30px) translateX(21px); }
  }

  #cig2 {
    animation: cig2 2s ease infinite;
    animation-delay: 0.5s;
    transform-origin: 98px 360px;
  }

  @keyframes cig2 {
    0% { transform: scale(1,1) translateY(30px) translateX(66px); }
    5% { transform: scale(1.1,.9) translateY(30px) translateX(66px); }
    15% { transform: scale(.9,1.1) translateY(30px) translateX(66px); }
    25% { transform: scale(1.05,.95) translateY(30px) translateX(66px); }
    28% { transform: scale(1,1) translateY(26px) translateX(66px); }
    50% { transform: scale(1,1) translateY(30px) translateX(66px); }
    100% { transform: scale(1,1) translateY(30px) translateX(66px); }
  }

  #cig3 {
    animation: cig3 2s ease infinite;
    animation-delay: 1s;
    transform-origin: 143px 360px;
  }

  @keyframes cig3 {
    0% { transform: scale(1,1) translateY(30px) translateX(111px); }
    5% { transform: scale(1.1,.9) translateY(30px) translateX(111px); }
    15% { transform: scale(.9,1.1) translateY(30px) translateX(111px); }
    25% { transform: scale(1.05,.95) translateY(30px) translateX(111px); }
    28% { transform: scale(1,1) translateY(26px) translateX(111px); }
    50% { transform: scale(1,1) translateY(30px) translateX(111px); }
    100% { transform: scale(1,1) translateY(30px) translateX(111px); }
  }

  #cig4 {
    animation: cig4 2s ease infinite;
    animation-delay: 1.5s;
    transform-origin: 188px 360px;
  }

  @keyframes cig4 {
    0% { transform: scale(1,1) translateY(30px) translateX(156px); }
    5% { transform: scale(1.1,.9) translateY(30px) translateX(156px); }
    15% { transform: scale(.9,1.1) translateY(30px) translateX(156px); }
    25% { transform: scale(1.05,.95) translateY(30px) translateX(156px); }
    28% { transform: scale(1,1) translateY(26px) translateX(156px); }
    50% { transform: scale(1,1) translateY(30px) translateX(156px); }
    100% { transform: scale(1,1) translateY(30px) translateX(156px); }
  }
}

#agehand {
  animation: agehand 350ms linear infinite alternate;
  transform-origin: 20px 30px;
}

@keyframes agehand {
  0% { transform: translate(116px, 38px) rotate(4deg) }
  100% { transform: translate(116px, 38px) rotate(-4deg) }
}

#fadelight {
  animation: fadelight 0.4s infinite alternate;
  opacity: 0.4;
}

@keyframes fadelight {
  0% { opacity: 0.4; }
  100% { opacity: 0; }
}
