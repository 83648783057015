@use '../styles/settings' as settings;
@use '../styles/colors' as color;

.button {
  @include settings.fontSizeSettings(default);
  font-weight: settings.$base-weight;
  padding: 0 31px;
  min-width: 64px;
  height: 124px;
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  overflow: hidden;
  vertical-align: middle;
  border-radius: settings.$edge;
  border: none;
  -webkit-tap-highlight-color: transparent;

  // ripple element
  &:before {
    position: absolute;
    border-radius: 50%;
    left: -50%;
    top: -50%;
    opacity: 1;
    transform: scale(0);
    background-color: rgba(255, 255, 255, 0.5);
    content: ' ';
    will-change: transform, opacity;
    width: var(--button-ripple-size);
    height: var(--button-ripple-size);
    top: var(--button-ripple-top);
    left: var(--button-ripple-left);
  }

  // lets animate
  &--touch-starts:before {
    animation: ripple 500ms linear;
  }

  .theme--migrolino-9ef12e & {
    font-family: "Suisse Screen SemiBold", sans-serif;
    border: 2px solid;
    border-color: color.colorVar(submit-button-font);
    box-shadow: none;
  }

  .theme--snabble-supermarket-71u283 .start &,
  .theme--demo .start & {
    top: 1636px;
  }

  &:active,
  &:focus,
  &:hover {
    box-shadow: none;
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    background-color: transparent;
    color: rgba(0, 119, 187, .37);
    cursor: default;
    pointer-events: none
  }

  &:not(:disabled) {
    // looks strange but it is correct :)
    color: color.colorVar(submit-button);
    background: color.colorVar(submit-font);
  }

  &--unelevated:not(:disabled) {
    background: color.colorVar(submit-button);
    color: color.colorVar(submit-button-font);

    .theme--tegut-eixae6 &,
    .theme--tegut-demo-n94qdzli &,
    .theme--snabble-supermarket-71u283 &,
    .theme--demo & {
      border: 2px solid #fff;
    }
  }

  &--outlined {
    border: 2px solid;
    font-weight: normal;

    &:before {
      background-color: rgba(0, 0, 0, 0.15);
    }

    // looks strange but it is correct :)
    &:not(:disabled),
    &:disabled {
      box-shadow: 0 8px 24px 0 color.colorVar(border);
      border-color: color.colorVar(border);
      color: color.colorVar(background-font);
      background: #fff;
    }
  }

  &--unstyled {
    color: unset;

    &:not(:disabled),
    &:disabled {
      color: unset;
    }
  }
}

@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}
