.offline {
  @extend %fullview;
  position: fixed;
  width: 100vw;
  z-index: 9999;
  background: colorVar(offline);
  color: colorVar(offline-font);

  // the correct offline theme for cigarette-picking
  // landing pages will appear after loading the assets
  // and the information about the checkout device 
  .theme--cigarette-picking & {
    background: var(--theme-background-image-main);
    color: colorVar(background-font);
  }

  &__content {
    @extend %content;

    .theme--tobaccoland-4257p2 &,
    .theme--cigarette-picking & {
      margin-top: #{$spacing * 4};
    }

    h1 {
      margin-bottom: calc($spacing / 2);
      color: colorVar(offline-font);

      .theme--tobaccoland-4257p2 &,
      .theme--cigarette-picking & {
        margin-top: $spacing;
        color: #fff;
      }
    }

    p {
      .theme--tobaccoland-4257p2 &,
      .theme--cigarette-picking & {
        @include fontSizeSettings(sub-headline);
      }
    }

    svg .darkmode {
      fill: #fff;
    }
  }

  .logo {
    margin-bottom: $spacing * 2;

    .theme--tobaccoland-4257p2 &,
    .theme--cigarette-picking & {
      margin-bottom: $spacing;
    }
  }
}
