// colors
$colors: (
  background: #fff,
  background-font: #000,
  background-font-meta: #777,
  background--darkmode: #000,
  background-font--darkmode: #fff,
  background-font-meta--darkmode: #fff,

  button: #fff,
  button-font: #000,
  button--darkmode: #111,
  button-font--darkmode: #fff,

  submit-button: #07b,
  submit-button-font: #fff,
  submit-button--darkmode: #000,
  submit-button-font--darkmode: #fff,

  success: #07b,
  success-font: #fff,

  error: #b61827,
  error-font: #fff,

  warning: #ffc107,
  warning-font: #000,

  reject: #b61827,
  reject-font: #fff,

  approve: #00c853,
  approve-font: #000,

  cancel: #ffc107,
  cancel-font: #000,

  border: rgba(0, 0, 0, 0.08),
  border--darkmode: rgba(255, 255, 255, 0.16),

  surface: #fafafa,
  surface-font: #07b,
  surface--darkmode: #111,
  surface--font--darkmode: #fff,

  offline: #000,
  offline-font: #fff,

  svg-main: #07b,

  cart-warning: #ffcfb8,
  cart-warning-font: #ff5200,
  cart-warning--darkmode: #ff5200,
  cart-warning-font--darkmode: #ffcfb8,

  cart-success: #cdeee1,
  cart-success-font: #047e4d,
  cart-success--darkmode: #047e4d,
  cart-success-font--darkmode: #cdeee1,

  cart-button-bg: #f0f0f6,
  cart-input-bg: #fff,
);

@function color($color-name) {
  @return map-get($colors, $color-name);
}

.theme {
  --theme-background: #{color(background)};
  --theme-on-background: #{color(background-font)};
  --theme-on-background-meta: #{color(background-font-meta)};
  --theme-on-background-headline: #{color(background-font)};

  --theme-background--darkmode: #{color(background--darkmode)};
  --theme-on-background--darkmode: #{color(background-font--darkmode)};
  --theme-on-background-meta--darkmode: #{color(background-font-meta--darkmode)};
  --theme-on-background-headline--darkmode: #{color(background-font--darkmode)};

  --theme-button: #{color(button)};
  --theme-on-button: #{color(button-font)};

  --theme-button--darkmode: #{color(button--darkmode)};
  --theme-on-button--darkmode: #{color(button-font--darkmode)};

  --theme-submit-button: #{color(submit-button)};
  --theme-on-submit-button: #{color(submit-button-font)};

  --theme-submit-button--darkmode: #{color(submit-button--darkmode)};
  --theme-on-submit-button--darkmode: #{color(submit-button-font--darkmode)};

  --theme-error: #{color(error)};
  --theme-on-error: #{color(error-font)};

  --theme-warning: #{color(warning)};
  --theme-on-warning: #{color(warning-font)};

  --theme-reject: #{color(reject)};
  --theme-on-reject: #{color(reject-font)};

  --theme-approve: #{color(approve)};
  --theme-on-approve: #{color(approve-font)};

  --theme-cancel: #{color(cancel)};
  --theme-on-cancel: #{color(cancel-font)};

  --theme-success: #{color(success)};
  --theme-on-success: #{color(success-font)};

  --theme-surface: #{color(surface)};
  --theme-on-surface: #{color(surface-font)};

  --theme-surface--darkmode: #{color(surface--darkmode)};
  --theme-on-surface--darkmode: #{color(surface-font--darkmode)};

  --theme-offline: #{color(offline)};
  --theme-on-offline: #{color(offline-font)};

  --theme-border: #{color(border)};
  --theme-border--darkmode: #{color(border--darkmode)};

  --theme-svg-main-color: #{color(svg-main)};

  --theme-cart-warning: #{color(cart-warning)};
  --theme-on-cart-warning: #{color(cart-warning-font)};

  --theme-cart-warning--darkmode: #{color(cart-warning--darkmode)};
  --theme-on-cart-warning--darkmode: #{color(cart-warning-font--darkmode)};

  --theme-cart-success: #{color(cart-success)};
  --theme-on-cart-success: #{color(cart-success-font)};

  --theme-cart-success--darkmode: #{color(cart-success--darkmode)};
  --theme-on-cart-success--darkmode: #{color(cart-success-font--darkmode)};

  --theme-feedback-button: #{color(feedback-button)};
}

@function colorVar($variable, $useDark: false) {
  // DARKMODE
  @if $useDark {
    @if $variable == 'background' {
      @return var(--theme-background--darkmode);
    }
    @if $variable == 'background-font' {
      @return var(--theme-on-background--darkmode);
    }
    @if $variable == 'background-font-meta' {
      @return var(--theme-on-background-meta--darkmode);
    }
    @if $variable == 'background-font-headline' {
      @return var(--theme-on-background-headline--darkmode);
    }

    @if $variable == 'button' {
      @return var(--theme-button--darkmode);
    }
    @if $variable == 'button-font' {
      @return var(--theme-on-button--darkmode);
    }

    @if $variable == 'submit-button' {
      @return var(--theme-submit-button--darkmode);
    }
    @if $variable == 'submit-button-font' {
      @return var(--theme-on-submit-button--darkmode);
    }

    @if $variable == 'border' {
      @return var(--theme-border--darkmode);
    }

    @if $variable == 'surface' {
      @return var(--theme-surface--darkmode);
    }

     @if $variable == 'surface-font' {
      @return var(--theme-on-surface--darkmode);
    }

    @if $variable=='cart-warning' {
      @return var(--theme-cart-warning--darkmode);
    }

    @if $variable=='cart-warning-font' {
      @return var(--theme-on-cart-warning--darkmode);
    }

    @if $variable=='cart-success' {
      @return var(--theme-cart-success--darkmode);
    }

    @if $variable=='cart-success-font' {
      @return var(--theme-on-cart-success--darkmode);
    }
  }

  // LIGHTMODE
  @if $variable == 'background' {
    @return var(--theme-background);
  }
  @if $variable == 'background-font' {
     @return var(--theme-on-background);
  }
  @if $variable == 'background-font-meta' {
     @return var(--theme-on-background-meta);
  }
  @if $variable == 'background-font-headline' {
    @return var(--theme-on-background-headline);
  }

  @if $variable == 'button' {
    @return var(--theme-button);
  }
  @if $variable == 'button-font' {
     @return var(--theme-on-button);
  }

  @if $variable == 'submit-button' {
    @return var(--theme-submit-button);
  }
  @if $variable == 'submit-button-font' {
     @return var(--theme-on-submit-button);
  }

  @if $variable == 'error' {
    @return var(--theme-error);
  }
  @if $variable == 'error-font' {
     @return var(--theme-on-error);
  }

  @if $variable == 'warning' {
    @return var(--theme-warning);
  }
  @if $variable == 'warning-font' {
     @return var(--theme-on-warning);
  }

  @if $variable == 'reject' {
    @return var(--theme-reject);
  }
  @if $variable == 'reject-font' {
     @return var(--theme-on-reject);
  }

  @if $variable == 'approve' {
    @return var(--theme-approve);
  }
  @if $variable == 'approve-font' {
     @return var(--theme-on-approve);
  }

  @if $variable == 'cancel' {
    @return var(--theme-cancel);
  }
  @if $variable == 'cancel-font' {
     @return var(--theme-on-cancel);
  }

  @if $variable == 'success' {
    @return var(--theme-success);
  }
  @if $variable == 'success-font' {
     @return var(--theme-on-success);
  }

  @if $variable == 'surface' {
    @return var(--theme-surface);
  }
  @if $variable == 'surface-font' {
     @return var(--theme-on-surface);
  }

  @if $variable == 'offline' {
    @return var(--theme-offline);
  }
  @if $variable == 'offline-font' {
     @return var(--theme-on-offline);
  }

  @if $variable == 'border' {
    @return var(--theme-border);
  }

  @if $variable == 'svg-main' {
    @return var(--theme-svg-main-color);
  }

  @if $variable=='cart-warning' {
    @return var(--theme-cart-warning);
  }

  @if $variable=='cart-warning-font' {
    @return var(--theme-on-cart-warning);
  }

  @if $variable=='cart-success' {
    @return var(--theme-cart-success);
  }

  @if $variable=='cart-success-font' {
    @return var(--theme-on-cart-success);
  }

  @if $variable == 'feedback-button' {
    @return var(--theme-feedback-button);
  }

  @return color($variable);
}

