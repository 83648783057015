$factor: 2.36;
$logo-height: 240px;
$mid-logo-height: 120px;

.logo {
  margin: auto;
  width: auto;
  height: $logo-height;

  .theme--tobaccoland-4257p2 &,
  .theme--cigarette-picking & {
    height: $mid-logo-height;
    margin-top: 190px;
  }
}
