.teaser {
  width: calc(50% - 20px); // 100% - margin
  border: 4px dashed colorVar(button);
  border-radius: $edge;
  padding: 35px;
  text-align: center;
  position: relative;

  &--full {
    width: 100%;
  }

  &__text {
    text-align: left;
    font-size: 6rem;
    line-height: 7.2rem;
    font-weight: $bold-weight;

    &--right {
      text-align: right;
    }

    .theme--cigarette-picking & {
      color: #fff; // hardcoded
    }
  }

  &--button {
    background: colorVar(button);
    border-style: solid;
    color: colorVar(button-font);

    p {
      color: colorVar(button-font);

      .theme--cigarette-picking & {
        color: colorVar(button-font);
      }
    }
  }

  &__image {
    &--top {
      margin-top: -50%;
      margin-bottom: calc($spacing / 2);
    }

    &--headline {
      margin-bottom: calc($spacing / 3);
    }

    &--left,
    &--right {
      position: absolute;
      bottom: -3%;

      & + p {
        width: 50%;
      }
    }

    &--left {
      left: -20%;

      & + p {
        margin-left: 50%;
      }
    }

    &--right {
      right: -20%;
    }
  }
}
