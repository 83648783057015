.product-teaser {
  width: 100%;
  background: color(background);
  border-color: color(border);
  box-shadow: 0 8px 24px 0 colorVar(border);
  border-radius: $edge;
  margin: 0 0 #{$edge * 4.5};

  display: flex;
  flex-direction: column;
  align-items: center;

  &__button {
    border: none;
    background: none;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__image {
    max-height: 100%;
    width: auto;
    border: 1px solid rgba(0,0,0,0.1);
  }

  &__image-container {
    overflow: hidden;
    height: 248px;
    width: 248px;
    margin: #{$edge * -2} auto 0 auto;
  }

  &__content {
    padding: $edge;
    display: flex;
    flex-direction: column;
    height: 152px;
  }

  &__name {
    font-weight: $bold-weight;
    font-size: 2.9rem;
    line-height: 3.6rem;
    height: 72px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__price {
    font-size: 2.9rem;
    line-height: 3.6rem;
    margin-top: auto;
    color: #999;
  }
}
