.numeric-customer-card {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &__header {
    margin-left: $spacing;
  }

  &__content {
    @extend %content;
  }

  &__image {
    margin-bottom: 80px;
  }

  .keyboard {
    flex: none;

    .hg-button {
      height: 124px;

      &:not(:last-child) {
        margin-right: 40px;
      }
    }

    .hg-row:not(:last-child) {
      margin-bottom: 40px;
    }

    .hg-button-lock {
      z-index: -9999;
    }

    .hg-button-enter {
      background: colorVar(submit-button);
      color: colorVar(submit-button-font);
    }
  }
}
