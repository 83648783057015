.approval-result {
  @extend %fullview;
  display: grid;
  grid-template-rows: 50% auto max-content;
  background-image: var(--theme-background-image-sub);
  background-repeat: no-repeat;
  background-position: top center;
  background-color: colorVar(background);

  &__success-hint {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    &__icon {
      z-index: 10;
      position: relative;
      background-color: white;
      border-radius: 50%;

      &--success {
        path {
          fill: colorVar(success);
        }
      }

      &--warning {
        path {
          fill: colorVar(warning);
        }
      }
    }

    &__text {
      @include fontSizeSettings(instruction);
      text-align: center;
      position: relative;
      z-index: 10;
      padding: $spacing;
    }

    &__animation {
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      left: 0;
    }
  }

  &__content {
    @extend %content;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    .approval-result--more-content & {
      margin-top: 0;
    }
  }

  &__back-button {
    width: 100%;
    margin-block: $spacing;
  }

  &__receipt {
    display: grid;
    grid-template-columns: fit-content 1fr;
    grid-template-rows: repeat(2, 90px);
    gap: 0 40px;
    padding: 40px;
    background-color: #ffF;
    border-radius: 16px;
    box-shadow: 0 8px 24px 0 rgba(34, 34, 34, 0.08);

    &--no-qr-code {
      display: block;
      padding: 80px;

      &--error {
          border: 1px solid #ef5350;
          background-color: rgb(253, 237, 237, 0.2);
      }
    }

    &__code {
      grid-column: 1;
      grid-row: 1 / 2;
    }

    &__hint {
      grid-column: 2;
      grid-row: 1;
      max-width: 730px;
      font-size: 30px;
      text-align: left;
      align-self: start;
    }

    &__button {
      grid-column: 2;
      grid-row: 2;
      max-height: 2.5em;
      width: fit-content;
      align-self: end;

      &.approval-result__receipt__button--small {
        font-size: 25px;
      }
    }
  }

  &__link {
    @include fontSizeSettings(default);
    background: none;
    border: none;
    color: colorVar(submit-button);

    &:disabled {
      opacity: 0.5;
    }
  }
}
