.cigarette-picking {
  background-image: var(--theme-background-image-main);
  background-position: top center;
  background-repeat: no-repeat;

  &__header {
    padding: 0 $spacing;
    margin-bottom: $spacing*1.5;
    text-align: center;

    h1 {
      color: #fff; // hardcoded
    }
  }

  &__text {
    @include fontSizeSettings(sub-headline);
    padding: 0 $spacing;
  }

  &__list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex: 1;
    align-content: flex-end;
    justify-content: space-between;
    padding: 0 60px calc($spacing / 2) 60px;
  }
}
