.css-animation__backdrop {
  position: fixed;

  width: 100vw;
  height: 100vh;

  background-color: var(--in-animation-background);
}

.css-animation__backdrop.css-animation_unmounted {
  background-color: var(--out-animation-background);
}

.css-animation__wrapper {
  animation: css-animation__animateIn var(--in-animation-duration) cubic-bezier(0.22, 0, 0.36, 0.99) forwards;
}

.css-animation__wrapper.css-animation_unmounted {
  animation: css-animation__animateOut var(--out-animation-duration) cubic-bezier(0.78, 0, 0.64, 1) forwards;
}

@keyframes css-animation__animateIn {
  from {
    opacity: var(--in-animation-opacity);
    transform:
      scale(var(--in-animation-scale))
      translateX(var(--in-animation-translateX))
      translateY(var(--in-animation-translateY))
      rotate(var(--in-animation-rotate))
    ;
  }
}

@keyframes css-animation__animateOut {
  to {
    opacity: var(--out-animation-opacity);
    transform:
      scale(var(--out-animation-scale))
      translateX(var(--out-animation-translateX))
      translateY(var(--out-animation-translateY))
      rotate(var(--out-animation-rotate))
    ;
  }
}