.language-switch {
  list-style-type: none;
  position: fixed;
  right: $spacing;
  top: $spacing;
  display: flex;
  pointer-events: inherit;
  opacity: 1;
  transform: scale(1);

  &__item {
    margin-left: calc($spacing / 2);
  }

  &__button {
    background: none;
    border: none;
    position: relative;

    // in case we have an object from asset service
    // we have use this to trigger the event
    &:after {
      position: absolute;
      content: ' ';
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }
}
