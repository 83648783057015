.dialog {
  flex-wrap: wrap;
  background: colorVar(background);
  @extend %fullview;
  align-items: flex-end;

  &__content {
    @extend %content;
    margin: 0;
  }

  &__image {
    margin-bottom: $spacing;
  }

  &__actions {
    display: flex;
    margin-top: auto;
    width: 100%;
    justify-content: space-between;
  }

  &__button {
    width: calc(50% - ($spacing / 2));
    margin-bottom: $spacing;
  }
}
