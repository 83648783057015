@use './settings' as settings;

%wrapper {
  width: 100%;
  max-width: 1080px;
  padding: 0 settings.$spacing;
  margin: 0 auto;
}

%content {
  width: 100%;
  max-width: 1080px;
  padding: 0 settings.$spacing;
  margin: auto;
  text-align: center;
}

%fullview {
  width: 100%;
  min-height: 100vh;
  display: flex;
  padding: 0 settings.$spacing;
}
