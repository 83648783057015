@use '../styles/colors' as color;

.feedback-input {
  width: 100%;

  &__title {
    font-size: 4.8rem;
    font-weight: bold;
    margin-bottom: 20px;
  }

  &__smiley-list {
    display: flex;
    justify-content: space-evenly;
  }

  &__smiley-button {
    background: rgba(255, 255, 255, 0);
    border: 2px solid rgba(0, 0, 0, 0);
    border-radius: 16px;
    padding: 15px;
    width: 124px;
    aspect-ratio: 1;
    transition: all 200ms ease-in-out;

    &--selected {
      background: rgb(255, 255, 255);
      background: color.colorVar(feedback-button);
      border: 2px solid rgba(0, 0, 0, 0.2);
    }
  }
}
