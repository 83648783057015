.led-color-preview {
  background-color: var(--color);
  border-radius: 100vh;
  height: 4rem;
  left: 50%;
  pointer-events: none;
  position: fixed;
  top: 2rem;
  transform: translateX(-50%);
  transition: background-color 300ms ease-out;
  width: 4rem;
  z-index: 9999;
}
