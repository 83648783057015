@use '../../styles/settings' as settings;
@use '../../styles/helper' as helper;
@use '../../styles/colors' as colors;

.approval-pending {
  display: flex;
  flex-wrap: wrap;
  @extend %fullview;

  &__content {
    align-self: center;
    @extend %content;
    padding-top: settings.$spacing * 5;
  }

  &__image {
    margin-bottom: calc(settings.$spacing / 2);

    .brandcolor {
      fill: colors.colorVar(svg-main);
    }
  }

  &__text {
    margin-bottom: calc(settings.$spacing / 2);
  }

  &__footer {
    align-self: end;
    width: 100%;
    padding-bottom: settings.$spacing;
  }

  &__btn {
    margin-top: auto;
    width: 100%;
  }
}
