.error {
  @extend %fullview;
  background-image: var(--theme-background-image-sub);
  background-repeat: no-repeat;
  background-position: top center;
  background-color: colorVar(background);

  &--warning {
    background: colorVar(warning);
  }

  &__content {
    @extend %content;
  }

  &__btn {
    margin: 100px 0;
    min-width: 30vw
  }
}
