.pop-up {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: rgba(80, 80, 80, 0.795);
  backdrop-filter: blur(5px);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;

  &__dialog {
    font-size: 40px;
    background-color: #fff;
    margin-inline: 80px;
    border-radius: 16px;
    padding-inline: 160px;
    padding-block: calc(80px / 2);
    display: grid;
    grid-template-rows: auto auto;
    gap: calc(80px / 2);

    &__button {
      width: fit-content;
      margin-inline: auto;
      padding-inline: 80px;
    }
  }
}